import {Collapse, FormGroup, Paper} from '@material-ui/core';
import React, {useState} from 'react';
import {createMoverLogin, createSample, getMoverPasswordLink, resendEmail, resetMoverPassword, updateJustifiBusinessData, updateMover} from "../../../firebase";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {ActionButton} from 'lib';
import AsyncSwitch from './AsyncSwitch';
import Button from "@material-ui/core/Button";
import DetailLine from './DetailLine';
import {EmailTypes} from '../../../util/types';
import ErrorDialog from '../ErrorDialog';
import LogoFile from './LogoFile';
import MoverForm from "../MoverForm";
import {makeStyles} from "@material-ui/styles";
import state from '../../../state';
import moment from 'moment/moment';

function MoverDetail() {
  const cn = useStyles();

  const setDetailsID = useSetRecoilState(state.movers.detailsID);
  const details = useRecoilValue(state.movers.details);
  const closeDetails = () => setDetailsID(null);

  const availability = useRecoilValue(state.availability.actual).filter(avail => avail.proID === details.id).sort((a, b) => (a.pickupStartAt > b.pickupStartAt ? 1 : -1));

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [stripeEmailSuccess, setStripeEmailSuccess] = useState(false);
  const [error, setError] = useState('');
  
  const toggleEditForm = () => setEditFormOpen(!editFormOpen)
  const closeErrors = () => setError('');
  const onSwitchError = err => setError(`Failed to update: ${err.message}`);
  const sendStripeSignupEmail = async () => {
    await resendEmail({
      proID: details.id,
      type: EmailTypes.stripeSignup,
    })
    .then(() => setStripeEmailSuccess(true))
    .catch(err => setError(`Failed to send email: ${err.message}`));
  };
  const onChangeSwitch = (field) => 
    (val) => updateMover(details.id, {[field]: val});
  
  const copyLink = async () => await navigator.clipboard.writeText(details.justifiOnboardingLink);
  
  // const getOnboardingLink = async () => {
  //   await getJustifiOnboardingLink(details.id)
  // }

  const updateJustifiData = async () => {
    const data = await updateJustifiBusinessData(details.id, details.justifi.bizId);
    // TODO: handle response
    console.log(data);
  }

  return (
    <div className={cn.moverDetail}>
      <div className={cn.infoSection}>
        <Button
          classes={{root: cn.backButton}}
          variant="contained"
          onClick={closeDetails}>
          <span className={cn.buttonLabel}>Back</span>
        </Button>
        <div className={cn.detailsTitle}>{details.name}</div>

        {/* Setting switches */}
        <FormGroup className={cn.switches}>
          <AsyncSwitch
            value={details.active}
            label="Active"
            onChange={onChangeSwitch('active')}
            onError={onSwitchError}/>
          <AsyncSwitch
            value={details.screenChat || false}
            label="Screen Chat"
            onChange={onChangeSwitch('screenChat')}
            onError={onSwitchError}/>
        </FormGroup>

        {/* Mover Details */}
        <DetailLine label="Contact Infos" values={[details.contact, details.email, details.phone]} />
        {
          Array.isArray(details.extraEmails) && details.extraEmails.length > 0 &&
          <DetailLine label="Extra emails" values={details.extraEmails} />
        }
        <DetailLine label="Country" values={[details.country]} />
        <DetailLine label="Website" values={[details.url]} />
        <DetailLine
          label="Address"
          values={[
            `${details.address1 || ""}${details.address2 ? ", " + details.address2 : ""}`,
            `${details.city ? details.city + ", " : ""} ${details.state || ""} ${details.zip || ""}`,
          ]} />
        {details.info && <DetailLine label="Additional Info" values={[details.info]} />}
        <DetailLine label="Blurb" values={[details.blurb]} />
        <DetailLine label="Moves Completed" values={[details.movesComplete]}/>
        {details.type === 'hauler' && availability.length &&
          <DetailLine
            label="Availability"
            values={availability.map(
              (avail) =>
                `${moment(avail.pickupStartAt.toDate()).format(
                  'MMM DD, YY'
                )} - ${moment(avail.pickupEndAt.toDate()).format('MMM DD, YY')}, ${
                  avail.pickup
                } - ${avail.destination}, ${
                  avail.insurance
                    ? `$${avail.insuranceCost} for $20k insurance, $${avail.deductible} deductible`
                    : 'No insurance'
                }`
            )}
          />
        }
        {details.type === 'hauler' && !availability.length &&
          <>
            <DetailLine
              label='Availability'
              values={['Unavailable']}
            />
          </>
        }
        <DetailLine label="Accepted Terms" values={[details.termsAccepted ? 'Yes' : 'No']}/>
        <DetailLine label="Mover Login Created" values={[details.userID ? 'Yes' : 'No']}/>
        {/* <DetailLine label="Mover Login Verified" values={[details.userVerified ? 'Yes' : 'No']}/> */}
        <DetailLine label="Stripe Payouts Setup" values={[details.stripe ? 'Yes' : 'No']}/>
          {
            details.appID && <DetailLine label="App ID" values={[details.appID]}/>
          }
        <LogoFile details={details} />
      </div>
      <div>
        {/* Action Buttons */}
        <Paper elevation={6} className={cn.actionButtons}>
          <ActionButton onClick={toggleEditForm}>
            {editFormOpen ? 'Cancel edit' : 'Edit Mover Details'}
          </ActionButton>
          <ActionButton
            onClick={() => createMoverLogin(details.id)}
            disabled={details.userID}
            spaced>
            Create Mover Login
          </ActionButton>
          <ActionButton
            onClick={async () => {
              const resetUrl = await getMoverPasswordLink(details.id);
              const win = window.open(resetUrl, '_blank');
              win.focus();
            }}
            disabled={!details.userID}
            spaced>
            Set Password
          </ActionButton>
          <ActionButton
            onClick={() => resetMoverPassword(details.id)}
            disabled={!details.userID}
            spaced>
            Email Password Reset
          </ActionButton>
          <ActionButton
            onClick={sendStripeSignupEmail}
            disabled={!!details.stripe || stripeEmailSuccess}
            spaced>
            Stripe Signup Email
          </ActionButton>
          <ActionButton
            onClick={() => createSample(details.id)}
            spaced>
            Send Sample Request
          </ActionButton>
          {/* <ActionButton onClick={() => sendJustifiEmail(details.id)}
            disabled={!details.active || !!details.justifi}
            spaced>
              Send Justifi Email
          </ActionButton>
          <ActionButton
            onClick={getOnboardingLink}
            spaced>
            { details.justifiOnboardingLink ? 'Renew Onboarding Link': 'Get Onboarding Link'}
          </ActionButton> */}
          {/* {
            details.justifiOnboardingLink && <ActionButton
              onClick={copyLink}
              spaced>
                Copy Onboarding Link
            </ActionButton>
          }
          {
            details.justifiOnboardingLink && details.justifi && <ActionButton
              onClick={updateJustifiData}
              spaced>
                Update Justifi Business
            </ActionButton>
          } */}
        </Paper>

        {/* Details Form */}
        <Paper elevation={5} className={cn.editForm}>
          <Collapse in={editFormOpen} timeout={500} unmountOnExit>
            <div className={cn.editFormContainer}>
              <div className={cn.formTitle}>Edit Details</div>
              <MoverForm onSuccess={toggleEditForm}/>
            </div>
          </Collapse>
        </Paper>
      </div>
      <ErrorDialog 
        open={!!error}
        handleClose={closeErrors}
        title={'Failure'}
        message={error}
      />
    </div>
  );
};

const useStyles = makeStyles({
  moverDetail: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '25px 50px 25px 25px',
  },
  infoSection: {
    flexGrow: 1,
  },
  backButton: {
    background: 'white',
    width: '100px',
  },
  detailsTitle: {
    marginTop: '35px',
    fontSize: '30px',
    marginBottom: '30px',
  },
  switches: {
    marginBottom: '30px',
  },
  formTitle: {
    marginBottom: "30px",
    fontSize: "25px",
    color: "#757575",
  },
  actionButtons: {
    width: "450px",
    padding: "30px 0px",
    marginBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  editForm: {
    width: "450px",
  },
  editFormContainer: {
    padding: "30px 60px 30px 30px",
  },
  field: {
    fontSize: "12px",
  },
});

export default MoverDetail;

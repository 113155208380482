import {emitter} from "lib/LoadingIndicator";
export default function useLoadingIndicator() {
  
    const showLoadingIndicator = () => {
        emitter.emit('show');
    };
  
    const hideLoadingIndicator = () => {
        emitter.emit('hide');
    };
  
    return {
        showLoadingIndicator,
        hideLoadingIndicator,
    };
  }
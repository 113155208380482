import React, {useState} from 'react';

import {TextField} from '@material-ui/core';
import _ from 'lodash';
import {formatNumeral} from 'cleave-zen';

const formatNumeralFn = (value) => formatNumeral(value || '', {
  prefix: '$ ',
  numeralPositiveOnly: true,
  numeralThousandsGroupStyle: 'thousand',
  numeralDecimalScale: 2,
})

const NumericInput = ({initialValue, onChange, label, name}) => {
  const [value, setValue] = useState(formatNumeralFn(initialValue || ''))

  const onChangeHandler = (e) => {
    const numericValueStr = formatNumeralFn(e.target?.value);
    const numericValue = parseFloat(numericValueStr.replace(/[^0-9.-]+/g, ''));
    setValue(numericValueStr);
    const eventCloned = _.cloneDeep(e);
    _.set(eventCloned, 'target.value', numericValue);
    if (typeof onChange === 'function') 
      onChange(eventCloned);
  }

  return (
    <TextField
        label={label}
        value={value}
        name={name}
        fullWidth
        margin="normal"
        autoComplete="off"
        onChange={onChangeHandler}
    />
    
  );
};

export default NumericInput;

import {selector} from 'recoil';
import {genSortedDocs} from './generic';

const {
  docs: movers,
  loaded,
  sort,
  sorted,
  detailsID,
  details,
  isDetailsOpen,
} = genSortedDocs('movers');

const filtered = selector({
  key: 'movers/active',
  get: ({get}) => ({
    activeMovers: get(sorted).filter((m) => m.active),
    inactiveMovers: get(sorted).filter((m) => !m.active),
  }),
});

const filteredNoHaulers = selector({
  key: 'movers/nohaulers',
  get: ({get}) => {
    const filterHaulers = (movers) =>
      movers.filter((mover) => mover.type !== 'hauler');
    const {activeMovers, inactiveMovers} = get(filtered);
    return {
      activeMovers: filterHaulers(activeMovers),
      inactiveMovers: filterHaulers(inactiveMovers),
    };
  },
});

const filteredHaulers = selector({
  key: 'movers/haulers',
  get: ({get}) => {
    const filterHaulers = (movers) =>
      movers.filter((mover) => mover.type === 'hauler');
    const {activeMovers, inactiveMovers} = get(filtered);
    return {
      activeMovers: filterHaulers(activeMovers),
      inactiveMovers: filterHaulers(inactiveMovers),
    };
  },
});

export default {
  movers,
  loaded,
  sort,
  sorted,
  filtered,
  filteredNoHaulers,
  filteredHaulers,
  detailsID,
  details,
  isDetailsOpen,
};

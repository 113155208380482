import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
      btnOk: {
          background: '#1D8DCD',
          color:'#FFFFFF',
          '&:hover':{
          background: '#1D8DCD',
          color:'#FFFFFF',
      },
    },
      titleDialog:{
        color:'gray',
        fontSize:'18px',
        paddingBottom:'8px',
      },
      dialogContent:{
        padding:'5px',
      },
    }));


const ConfirmDialog = ({
  open, handleClose, handleConfirm, title, message,
}) => {

  const cn = useStyles();
    
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-slide-description"
      className={cn.dialogContent}
    >
      <DialogTitle id="alert-dialog-title" className={cn.titleDialog}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
            {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleClose} >
        Close
      </Button>
        <Button onClick={handleConfirm} variant="contained" className={cn.btnOk}>
        Ok
      </Button>
      </DialogActions>
    </Dialog>
  );
}


ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.any, //string or Array
};

ConfirmDialog.defaultProps = {
  message: '',
};

export default ConfirmDialog;

import {selector} from 'recoil';
import {genSortedDocs} from './generic';

const {
  docs: availability,
  loaded,
  sort,
  sorted,
  detailsID,
  details,
  isDetailsOpen,
} = genSortedDocs('availability');

const actual = selector({
  key: 'availability/active',
  get: ({get}) =>
    get(sorted)
      .filter((avail) => avail.active)
      .filter((avail) => avail.pickupEndAt.toDate() >= new Date()),
});

export default {
  availability,
  loaded,
  sort,
  sorted,
  actual,
  detailsID,
  details,
  isDetailsOpen,
};

import React, {useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import state from '../../state';
import Loading from '../Loading';
import HaulersIndex from './Index/HaulersIndex';
import MoverDetail from './Detail/MoverDetail';
import {getAvail} from '../../firebase';

function HaulersPanel() {
  const moversLoaded = useRecoilValue(state.movers.loaded);
  const setAvail = useSetRecoilState(state.availability.availability);
  const setDetailsID = useSetRecoilState(state.movers.detailsID);
  const isDetailsOpen = useRecoilValue(state.movers.isDetailsOpen);

  useEffect(() => {
    const unsub = getAvail(setAvail);
    return unsub;
  }, []);

  useEffect(() => {
    setDetailsID(null);
    return () => {
      setDetailsID(null);
    };
  }, []);

  return !moversLoaded ? (
    <Loading />
  ) : isDetailsOpen ? (
    <MoverDetail />
  ) : (
    <HaulersIndex />
  );
}

export default HaulersPanel;

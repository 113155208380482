import {Backdrop, CircularProgress} from '@material-ui/core';

import {makeStyles} from "@material-ui/styles";
import mitt from 'mitt'
import {useState} from "react";

export const emitter = mitt();

const useStyles = makeStyles({
    backdrop: {
      zIndex: 10000,
      color: '#fff',
    },
});

export function LoadingIndicator() {
    const cn = useStyles();
    const [show, setShow] = useState(false);

    emitter.on('show', () => setShow(true));
    emitter.on('hide', () => setShow(false));

    return (
        <Backdrop className={cn.backdrop} open={show}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
import {useState, useMemo} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {makeStyles} from '@material-ui/styles';
import {OutlinedInput} from '@material-ui/core';
import IndexHeader from './IndexHeader';
import IndexTabs from './IndexTabs';
import HaulerListing from './Listing/HaulerListing';
import IndexFilter from './IndexFilter';
import state from '../../../state';

const useStyles = makeStyles({
  haulersIndex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
  },
  emptyText: {
    textAlign: 'center',
  },
});

function HaulersIndex() {
  const styles = useStyles();
  const [currentTab, setCurrentTab] = useState('ACTIVE');
  const [search, setSearch] = useState('');
  const setMoversSort = useSetRecoilState(state.movers.sort);

  let {activeMovers, inactiveMovers} = useRecoilValue(
    state.movers.filteredHaulers
  );
  const avail = useRecoilValue(state.availability.actual);

  const availAdd = (movers) =>
    movers.map((mover) => ({
      ...mover,
      availability: avail
        .filter((a) => a.proID === mover.id)
        .sort((a, b) => (a.pickupStartAt > b.pickupStartAt ? 1 : -1)),
    }));

  activeMovers = availAdd(activeMovers);
  inactiveMovers = availAdd(inactiveMovers);

  const onTabChange = (event, newValue) => setCurrentTab(newValue);
  const genOnSort = (key, dir) => () => setMoversSort({key, dir});

  const data = useMemo(() => {
    const haulers = currentTab === 'ACTIVE' ? activeMovers : inactiveMovers;
    const filteredData = search.length
      ? haulers.filter((r) =>
          r.name.toLowerCase().includes(search.toLocaleLowerCase())
        )
      : haulers;
    return filteredData.sort((a, b) => {
      if (a.availability.length && !b.availability.length) return -1;
      if (!a.availability.length && b.availability.length) return 1;
      return 0;
    });
  }, [currentTab, activeMovers, inactiveMovers, search]);

  return (
    <div className={styles.haulersIndex}>
      <IndexHeader title={'Haulers'} />
      <IndexTabs
        currentTab={currentTab}
        handleChange={onTabChange}
        tabs={[
          {value: 'ACTIVE', label: `Active - ${activeMovers.length}`},
          {value: 'INACTIVE', label: `Inactive - ${inactiveMovers.length}`},
        ]}
      />
      <IndexFilter
        filterButtons={[
          {label: 'Name ASC', func: genOnSort('name', 'asc')},
          {label: 'Name DESC', func: genOnSort('name', 'desc')},
          {label: 'Moves ASC', func: genOnSort('movesComplete', 'asc')},
          {label: 'Moves DESC', func: genOnSort('movesComplete', 'desc')},
        ]}
        custom={
          <OutlinedInput
            type="search"
            margin="dense"
            placeholder="Search..."
            className={styles.searchInput}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
      />
      <HaulerListing data={data} />
      {data.length === 0 && (
        <p className={styles.emptyText}>No matches for your search</p>
      )}
    </div>
  );
}

export default HaulersIndex;

import React, {useCallback, useEffect, useState} from 'react';
import {
  filterActiveQuotes,
  filterCompletedQuotes,
  filterExpiredQuotes,
  filterLiveTestQuotes,
  filterPresubmitQuote,
  filterRejectedQuotes,
} from "../../../util/status";
import {useRecoilValue, useSetRecoilState} from 'recoil';

import CustomerListing from "./Listing/CustomerListing";
import Filters from '../Detail/customer/Filters';
import FormControl from '@material-ui/core/FormControl';
import IndexFilter from "./IndexFilter";
import IndexHeader from "./IndexHeader";
import IndexTabs from "./IndexTabs";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import {getMoversWithWebApp} from '../../../firebase';
import {makeStyles} from "@material-ui/styles";
import state from '../../../state';

const useStyles = makeStyles({
  customersIndex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px',
  },
  emptyText: {
    textAlign: 'center',
  },
  select: {
    maxWidth: '250px',
  },
});

function CustomersIndex({isMover = false} = {}) {
  const 
    styles = useStyles(),
    customers = useRecoilValue(state.customers.sorted),
    setCustomersSort = useSetRecoilState(state.customers.sort),

    [counters, setCounters] = useState({}),
    [filteredData, setFilteredData] = useState([]),
    [currentTab, setCurrentTab] = useState('ACTIVE'),

   {activeMovers} = useRecoilValue(state.movers.filtered),
   [search, setSearch] = useState(''),
   [mover, setMover] = useState(''),
   [moversWithWebApp, setMoversWithWebApp] = useState([]),
   [webApps, setWebApps] = useState([]),

   customerFilters = {
    PRESUBMIT: filterPresubmitQuote(customers),
    ACTIVE: filterActiveQuotes(customers),
    COMPLETED: filterCompletedQuotes(customers),
    EXPIRED: filterExpiredQuotes(customers),
    REJECTED: filterRejectedQuotes(customers),
    TEST: filterLiveTestQuotes(customers),
  };


  const countCustomers = () => {
    const newCounters = {};
    Object.keys(customerFilters).forEach((key) => {
      newCounters[key] = filterData(customerFilters[key]).length;
    });
   // newCounters.TEST = customerFilters.TEST.length; 
    setCounters(newCounters);
  };

  function handleChange(event, newValue) {
    setCurrentTab(newValue);
  }

  function handleSortCustomers(key, dir) {
    return () => setCustomersSort({key, dir});
  }
  
  const filterData = (data) => {
    return data.filter((r) => {
      const matchesSearch = search.length ? 
        _.get(r, 'contact.name', '').toLowerCase().includes(search.toLowerCase()) : 
        true;
      const matchesMover = mover.length ? 
        webApps.find((app) => app.proId === mover)?.id === r.appID : 
        true;

      return matchesSearch && matchesMover;
    });
  };

  const loadMoversWithWebApp = useCallback(async () => {
    const response = await getMoversWithWebApp();
    const moversIds = response.map((r) => r.proId);
    const moversWithWebAppFromResponse = activeMovers.filter((r) => moversIds.includes(r.id));
    setMoversWithWebApp(moversWithWebAppFromResponse);
    setWebApps(response);
  }, [activeMovers, setMoversWithWebApp, setWebApps])
  
  useEffect(() => {
    countCustomers();
  }, [mover]);
 
  useEffect(() => {
    setFilteredData(filterData(customerFilters[currentTab]));
  }, [currentTab, customers, search, mover, webApps]); 

  useEffect(() => {
    if (!isMover) loadMoversWithWebApp();
  }, [loadMoversWithWebApp, isMover]);
 
  return (
    <div className={styles.customersIndex}>
      <IndexHeader
        title={"Customer Quotes"}
      />
      {
        !isMover && <FormControl>
          <InputLabel id="custom-company-select-label">Web app</InputLabel>
          <Select
            className={styles.select}
            id="custom-company-select"
            labelId="custom-company-select-label"
            value={mover}
            onChange={(e) => {setMover(e.target.value); setSearch('')}}
            label="Custom"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {
              moversWithWebApp.map((r) => <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>)
            }
          </Select>
        </FormControl>
      }

      <IndexTabs
        currentTab={currentTab}
        handleChange={handleChange}
        tabs={[
          {value: "PRESUBMIT", label: `Presubmit - ${counters.PRESUBMIT || 0}`},
          {value: "ACTIVE", label: `Active - ${counters.ACTIVE || 0}`},
          {value: "COMPLETED", label: `Completed - ${counters.COMPLETED || 0}`},
          {value: "EXPIRED", label: `Expired - ${counters.EXPIRED || 0}`},
          {value: "REJECTED", label: `Rejected - ${counters.REJECTED || 0}`},
          {value: "TEST", label: `Test - ${counters.TEST || 0}`},
        ]}
      />

      <IndexFilter
        filterButtons={
          [
            {label: "Name ASC", func: handleSortCustomers('contact.name', 'asc')},
            {label: "Name DESC", func: handleSortCustomers('contact.name', 'desc')},
            {label: "Status ASC", func: handleSortCustomers('state', 'asc')},
            {label: "Status DESC", func: handleSortCustomers('state', 'desc')},
            {label: "Modified ASC", func: handleSortCustomers('lastModified', 'asc')},
            {label: "Modified DESC", func: handleSortCustomers('lastModified', 'desc')},
          ]
        }
        custom={
          <Filters
            search={search}
            onSearch={(e) => setSearch(e.target.value)}
          />
        }
      />

      <CustomerListing
        data={filteredData}
      />

      {
        filteredData.length === 0 && <p className={styles.emptyText}>No matches for your search</p>
      }
    </div>
  );
}

export default CustomersIndex;
import {useState} from "react";

export const useToggle = (initialValue) => {
  const [current, setCurrent] = useState(initialValue || false);

  const toggle = () => setCurrent((prev) => !prev);

  const setFalse = () => setCurrent(false);
  const setTrue = () => setCurrent(true);

  return {current, setFalse, setTrue, toggle, setCurrent};
};
import React from "react";
import {makeStyles} from "@material-ui/styles";
import {hashCode} from "../../../util/util";

const useStyles = makeStyles({
  detailLine: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    color: "#757575",
  },
  detailLineLong: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    color: "#757575",
  },
  labelArea: {
    width: "40%",
    maxWidth: 200,
  },
  labelAreaLong: {
    width: '100%',
  },
  label: {
    fontWeight: "700",
    lineHeight: 1.5,
  },
  detailValue: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    lineHeight: 1.5,
  },
  detailValueLong: {
    display: "flex",
    flexDirection: "column",
    lineHeight: 1.5,
  },
  strikeLink: {
    textDecoration: 'line-through',
  },
});

function DetailLine({className, label, values, long, link, strikeLink}) {
  const styles = useStyles();
  const key = hashCode(label + String(values));
  return (
    <div key={key} className={`${long ? styles.detailLineLong : styles.detailLine} ${className || ''}`}>
      <div className={long ? styles.labelAreaLong : styles.labelArea}>
        <span className={styles.label}>{label}:</span>
      </div>
      <div className={long ? styles.detailValueLong : styles.detailValue}>
        {link &&
          <a href={link.includes('localhost') ? `${link}` : `https://${link}`}>
            <span className={strikeLink ? styles.strikeLink : null}>{link || ''}</span>
          </a>
        }
        {values && values.map((val,i) => <span key={hashCode(String(val)+String(i))}>{val || ''}</span>)}
      </div>
    </div>
  );
}

export default DetailLine;

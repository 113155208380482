import React from 'react';
import {useSetRecoilState} from 'recoil';
import state from '../../../../state';
import {List} from '@material-ui/core';
import ListingRow from './ListingRow';
import moment from 'moment/moment';

function HaulerListing({data}) {
  const setDetailsID = useSetRecoilState(state.movers.detailsID);
  const onDetailOpen = (details) => setDetailsID(details.id);

  return (
    <List>
      {data.map((hauler) => {
        const {id, name, email, phone, availability} = hauler;

        let middle = 'Unavailable',
          right = '';

        if (availability.length) {
          const shortList = availability.slice(0, 4);

          middle = shortList
            .map(
              (avail) =>
                `${moment(avail.pickupStartAt.toDate()).format(
                  'MMM DD, YY'
                )} - ${moment(avail.pickupEndAt.toDate()).format('MMM DD, YY')}`
            )
            .join('\n');

          right = shortList
            .map((avail) => `${avail.pickup} - ${avail.destination}`)
            .join('\n');
        }

        return (
          <ListingRow
            key={id}
            data={hauler}
            leftPrimary={name}
            leftSecondary={`${email} - ${phone}`}
            middlePrimary={middle}
            rightPrimary={right}
            buttonLabel={'Details'}
            buttonAction={onDetailOpen}
          />
        );
      })}
    </List>
  );
}

export default HaulerListing;

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import React, {useState} from 'react';

import NumericInput from 'components/NumericInput';
import {createPaymentLink} from '../../../../firebase';
import {toast} from 'sonner';
import {withStyles} from '@material-ui/core/styles';

const CustomCheckbox = withStyles({
  root: {
    color: 'black',
    '&$checked': {
      color: '#0E75BB',
    },
  },
  checked: {},
})(Checkbox);

const originalFormData = {
  amount: '',
  deposit: '',
  processor: {
    creditCard: false,
    bankTransfer: false,
  },
  title: '',
}

const PaymentLinkDialog = ({open, onClose, orderID}) => {
  const [formData, setFormData] = useState(structuredClone(originalFormData));
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData((prev) => ({...prev, [name]: value}));
  };

  const handleProcessorChange = (e) => {
    const {name, checked} = e.target;
    setFormData((prev) => ({
      ...prev,
      processor: {...prev.processor, [name]: checked},
    }));
  };

  const isFormValid = () => {
    const {amount, deposit, title, processor} = formData;
    return (
      amount && deposit && title && (processor.creditCard || processor.bankTransfer)
    );
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      setIsLoading(true);
      const {amount, deposit, title: name, processor: paymentMethod} = formData;
      const payload = {
        orderID,
        name,
        amount: amount * 100,
        deposit: deposit * 100,
        processor: 'stripe',
        paymentMethod: [...(paymentMethod.bankTransfer? ['us_bank_account']: []), ...(paymentMethod.creditCard? ['card']: [])],
      };
      try {
        await createPaymentLink(payload);
        toast.success('Payment links created')
        onClose();
      } catch (e) {
        console.log(e)
        toast.error('There was an error, please try again')
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Payment Link Information</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          autoComplete="off"
          margin="normal"
        />
        <NumericInput
          label="Amount"
          name="amount"
          initialValue={formData.amount}
          onChange={handleChange}
        />
        <NumericInput
          label="Deposit"
          name="deposit"
          initialValue={formData.deposit}
          onChange={handleChange}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.processor.creditCard}
                onChange={handleProcessorChange}
                name="creditCard"
              />
            }
            label="Credit Card"
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.processor.bankTransfer}
                onChange={handleProcessorChange}
                name="bankTransfer"
              />
            }
            label="Bank Transfer"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose}>Cancel</Button>
        {
          isLoading ? <CircularProgress size={20} /> :
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={!isFormValid()}
          >
            Submit
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default PaymentLinkDialog;

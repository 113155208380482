import 'moment-timezone';

import * as Firebase from "../../../../firebase";
import * as moment from 'moment';

import {ActionButton, ActionDiv} from 'lib';
import React, {useState} from "react";
import {
  ServiceStates,
  allowAssignMover,
  allowClose,
  allowMarkCompleted,
  allowResend,
  allowRetryPayment,
  allowSetExpiration,
} from 'util/status';

import ErrorDialog from '../../ErrorDialog';
import PaymentLinkDialog from './PaymentLinkDialog';
import RejectAlertDialog from './RejectAlertDialog';
import _ from 'lodash';
import {makeStyles} from "@material-ui/styles";
import state from 'state';
import {useSetRecoilState} from 'recoil';
import {useToggle} from 'util/useToggle';

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  loading: {
    marginRight: '10px',
  },
});

////
// TODO: Come Test out reject
////

const ActionButtons = ({
  fade, details, orderID,
  assignFormToggle, deadlineFormToggle, closeFormToggle,
}) => {
  const styles = useStyles(),
        setDetailsID = useSetRecoilState(state.customers.detailsID),
        closeDetails = () => setDetailsID(null),
        [openRejectAlert, setOpenRejectAlert] = useState(false),
        [submitting, setSubmitting] = useState(false),
        [resendSuccess, setResendSuccess] = useState(false),
        [errorMsg, setErrorMsg] = useState('');
  const {current: show, setFalse: onClose, setTrue: onShow} = useToggle(false);
  
  const serviceAt = (
    Object.values(details.services)
      .filter(s => !!s.quote) 
      .sort((a, b) => a.quote.at.toMillis() - b.quote.at.toMillis())
  )[0]?.quote.at?.toMillis();

  // function handleClickOpenRejectAlert() {
  //   setOpenRejectAlert(true);
  // };

  function handleCloseRejectAlert() {
    setOpenRejectAlert(false);
  };

  const handleRejectClick = () => {
    setSubmitting(true);
    return Firebase.adminReject(orderID)
      .finally(() => {
        setSubmitting(false);
        setOpenRejectAlert(false);
        closeDetails();
      });
  };

  const handleResendEmailClick = () => 
    Firebase.resendEmail({orderID}) //type: 'FEEDBACK' })
      .then(() => setResendSuccess(true));

  const handleRetryAuthorize = () => Firebase.retryAuthorize(details.links.customer);
  const handleMarkCompleted = () => Firebase.markCompleted(orderID);
  const handleSetTest = () => Firebase.markTest(orderID);

  const onError = err => setErrorMsg(err.message);

  return (
    <ActionDiv fade={fade}>
      <div className={styles.root}>
        <ActionButton 
          onClick={closeFormToggle}
          onError={onError}
          disabled={!allowClose(details.state)}>
          Close Request
        </ActionButton>
        {
          (!details?.appID || details.appID === 'Muva Freight') && <ActionButton
            onClick={assignFormToggle}
            onError={onError}
            disabled={!allowAssignMover(details.state)}>
            Assign Mover
          </ActionButton>
        }
        <ActionButton 
          onClick={deadlineFormToggle}
          onError={onError}
          disabled={!allowSetExpiration(details.state)}>
          Set Deadline
        </ActionButton>
        <ActionButton 
          onClick={handleResendEmailClick}
          onError={onError}
          disabled={!allowResend(details.state) || resendSuccess}>
          Resend Last Email
        </ActionButton>
        <ActionButton
          onClick={handleRetryAuthorize}
          onError={onError}
          disabled={!_.some(details.services, service => service.state === ServiceStates.authFailed)}>
          Retry Authorization
        </ActionButton>
        <ActionButton 
          onClick={() => Firebase.retryPayment(orderID)}
          onError={onError}
          disabled={!allowRetryPayment(details.state)}>
          Retry Payment
        </ActionButton>
        <ActionButton
          onClick={handleMarkCompleted}
          onError={onError}
          disabled={!allowMarkCompleted(details.state)}>
          Mark Complete
        </ActionButton>
        <ActionButton
          onClick={() => Firebase.sendReviewRequest(orderID)}
          onError={onError}
          disabled={!_.some(details.services, s => s.feedback) || details.reviewRequested}>
          Send $50 Review Request
        </ActionButton>
        <ActionButton
          onClick={handleSetTest}
          onError={onError}
          disabled={!!details.test}>
          Mark Test
        </ActionButton>
        {
          (_.isEmpty(details.paymentLinks) && ['init', 'new'].includes(details.state)) && 
          <ActionButton
            onClick={onShow}>
            Create payment links
          </ActionButton>
        }
        { process.env.NODE_ENV === 'development' && (
          <>
            <div style={{marginTop: 20}}>Dev Actions:</div>
            <ActionButton 
              onClick={() => Firebase.resetQuoteToNew(orderID)}
              onError={onError}>
              Reset to NEW
            </ActionButton>
            <ActionButton
              onClick={Firebase.minuteJob}
              onError={onError}>
              Minute Job
            </ActionButton>
            <ActionButton
              onClick={Firebase.testExpireLinks}
              onError={onError}>
              Test Expire Links
            </ActionButton>
            <ActionButton 
              onClick={() => Firebase.doTimeShift(
                orderID, 
                moment(serviceAt).subtract(4, 'days').add(1, 'minute').valueOf()
              )}
              onError={onError} >
              Set Move in 4 days (for auth test)
            </ActionButton>
            <ActionButton 
              onClick={Firebase.testAuthorizePayments}
              onError={onError}>
              Authorize Payments
            </ActionButton>
            <ActionButton
              onClick={() => Firebase.doTimeShift(
                orderID,
                moment(serviceAt).subtract(2, 'days').add(1, 'minute').valueOf()
              )}
              onError={onError}>
              Set Move in 2 days (for authfail test)
            </ActionButton>
            <ActionButton 
              onClick={Firebase.testInvoiceMailer}
              onError={onError}>
              Invoice Mailer
            </ActionButton>
            <ActionButton 
              onClick={() => Firebase.doTimeShift(
                orderID, 
                moment(serviceAt).add(1, 'day').set('hour', 16).set('minute', 20).valueOf())
              }
              onError={onError}>
              Set Move Happened
            </ActionButton>
            <ActionButton 
              onClick={Firebase.processStripeEvents}
              onError={onError}>
              Process Stripe Events
            </ActionButton>
            <ActionButton
              onClick={() => Firebase.deleteQuote(orderID).then(closeDetails)}
              onError={onError}>
              Delete
            </ActionButton>
            <ActionButton
              onClick={() => new Promise(resolve => setTimeout(resolve, 5000))}>
              TEST ACTION BUTTON
            </ActionButton>
          </>
        )}
      </div>
      <RejectAlertDialog
        submitting={submitting}
        open={openRejectAlert}
        handleClose={handleCloseRejectAlert}
        handleReject={handleRejectClick}
      />
      <ErrorDialog
        open={!!errorMsg}
        handleClose={() => setErrorMsg('')}
        title={"Uh Oh"}
        message={errorMsg}
      />
      <PaymentLinkDialog open={show} onClose={onClose} />
    </ActionDiv>
  );
}

export default ActionButtons;

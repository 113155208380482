import CustomersPanel from './CustomersPanel';
import MoversPanel from './MoversPanel';
import MuvaFreightPanel from './MuvaFreightPanel';
import Navbar from '../Navbar/Navbar';
import QuotePanel from './QuotePanel';
import HaulersPanel from './HaulersPanel';
import React from 'react';
import Sidebar from './Sidebar';
import {makeStyles} from '@material-ui/styles';
import state from '../../state';
import {useRecoilValue} from 'recoil';

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    backgroundColor: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
  },
  cols: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
});

function Dashboard() {
  const cn = useStyles();
  const panelState = useRecoilValue(state.app.panel);

  return (
    <div className={cn.root}>
      <Navbar />
      <div className={cn.cols}>
        <Sidebar />
        {(() => {
          switch (panelState) {
            case 'CUSTOMER':
              return <CustomersPanel />;
            case 'MOVER':
              return <MoversPanel />;
            case 'MUVA FREIGHT':
              return <MuvaFreightPanel />;
            case 'QUOTE':
              return <QuotePanel />;
            case 'HAULER':
              return <HaulersPanel />;
            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
}

export default Dashboard;

import app from './recoil/app';
import customers from './recoil/customers';
import movers from './recoil/movers';
import discounts from './recoil/discounts';
import quotes from './recoil/quotes';
import availability from './recoil/availability';

const state = {app, customers, discounts, movers, quotes, availability};

export default state;

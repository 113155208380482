import React, {useEffect, useMemo} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {makeStyles} from '@material-ui/styles';
import {List, ListItem, ListItemText} from '@material-ui/core';

import state from '../../state';

const useStyles = makeStyles({
  sidebar: {
    minWidth: 200,
    maxWidth: 200,
    minHeight: '100%',
    borderRight: 'solid #D6D6D6D6 1px',
    display: 'flex',
    flexDirection: 'column',
  },
  tabLabel: {
    fontSize: 25,
    cursor: 'pointer',
  },
  unselected: {
    color: '#757575',
  },
});

function Sidebar() {
  const styles = useStyles();
  const [panelState, setPanelState] = useRecoilState(state.app.panel);
  const loggedInData = useRecoilValue(state.app.loggedInData);

  const tabs = useMemo(() => {
    if (loggedInData.admin)
      return [
        {
          label: 'Customers',
          state: 'CUSTOMER',
        },
        {
          label: 'Movers',
          state: 'MOVER',
        },
        {
          label: 'Haulers',
          state: 'HAULER',
        },
        {
          label: 'Muva Freight',
          state: 'MUVA FREIGHT',
        },
      ];
    else
      return [
        {
          label: 'Quotes',
          state: 'CUSTOMER',
        },
      ];
  }, [loggedInData.admin]);

  useEffect(() => {
    if (!panelState) setPanelState('CUSTOMER');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.sidebar}>
      <List>
        {tabs.map((tab) => {
          return (
            <ListItem key={`${tab.label}-tab`}>
              <ListItemText
                classes={{
                  primary:
                    styles.tabLabel +
                    ' ' +
                    (tab.state !== panelState ? styles.unselected : ''),
                }}
                primary={tab.label}
                onClick={() => setPanelState(tab.state)}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export default Sidebar;
